<template>
  <div class="home">
    <!-- 菜单栏 -->
    <div class="menulist" ref="menu">
      <div>
        <img v-if="!isCollapse" src="../assets/home/big-logo.png" alt="" class="bigLogo" @click="toIndex" />
        <img v-else src="../assets/home/small-logo.png" class="smallLogo" alt="" @click="toIndex" />
      </div>
      <el-menu unique-opened :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse"
        active-text-color="#FFF" text-color="#FFF">
        <template v-for="item in menuList">
          <el-menu-item v-if="item.children && item.children.length === 0" :key="item.id" :index="item.frontPath + ''"
            @click="routerUrl(item.frontPath)">
            <!-- <i class="el-icon-location"></i> -->
            <img :src="item.iconUrl" alt="" style="width: 24px" />
            <span slot="title">{{ item.permissionName }}</span>
          </el-menu-item>
          <el-submenu v-else :key="item.id" :index="item.id + ''">
            <template slot="title">
              <img :src="item.iconUrl" alt="" style="width: 24px" />
              <span slot="title">{{ item.permissionName }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="child in item.children">
                <el-menu-item class="menu-item-group" :key="child.id" :index="child.frontPath + ''"
                  @click="routerUrl(child.frontPath)">
                  <span style="margin-left: 25px">{{
                    child.permissionName
                  }}</span></el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div style="width: 100%; overflow: hidden; background: #eee">
      <!-- 头部 -->
      <div class="header">
        <div class="control">
          <div @click="isCollapse = !isCollapse">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
          </div>
          <div>
          
            <div style="width: 300px; display: flex; align-items: center">
              <div class="title">{{ this.$route.name }}</div>
              <el-select v-model="projectName" filterable placeholder="请选择项目" class="select-project"
                @change="changeProject($event)">
                <el-option v-for="item in projectList" :key="item.id" :label="item.projectName" :value="item.projectId"
                  style="color: rgb(195, 226, 249)"></el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="avatar">
          <el-popover placement="top" width="160" v-model="visible" trigger="hover">
            <div class="logout" style="cursor: pointer" @click="logOpen()">
              退出登录
            </div>
            <!-- <img  src="../assets/home/avatar.png" alt="" slot="reference" /> -->
            <img style="width:2.5rem;border-radius: 50%;border-color: black;" src="../assets/home/logo.jpg" alt="" slot="reference" />
          </el-popover>
          <span>{{ account }}</span>
        </div>
      </div>
      <div class="view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import deviceImg from "../assets/home/device.png";
import smartImg from "../assets/home/smart.png";
import alarmImg from "../assets/home/alarm.png";
import systemImg from "../assets/home/system.png";
export default {
  data() {
    return {
      projectName: "字景物联网项目",
      selectedData: "",
      // 菜单列表是否展开
      isCollapse: false,
      visible: false,
      // 菜单列表
      menuList: [
        {
          title: "设备信息",
          flag: true,
          id: "1",
          icon: deviceImg,
          path: "/DevManager",
        },
        {
          title: "开关控制",
          flag: true,
          id: " 2",
          icon: smartImg,
          path: "/SwitchControl",
        },
        {
          title: "报警管理",
          flag: true,
          id: "3",
          icon: alarmImg,
          path: "/Alarm",
        },
        {
          title: "系统设置",
          flag: true,
          id: "4",
          icon: systemImg,
          children: [
            { title: "角色管理", id: "4-1", path: "/roleManage" },
            { title: "账户管理", id: "4-2", path: "/AccontManage" },
            { title: "修改密码", id: "4-3", path: "/EditPsw" },
          ],
        },
      ],
      // 头部宽度
      headerWidth: "",
      projectList: [{ id: null, projectName: "", projectID: '' }],
      options: [
        {
          value: "选项1",
          label: "字景数科",
        },
        {
          value: "选项2",
          label: "白象电器",
        },
      ],
      value: "",
      account: localStorage.getItem("account"),
    };
  },
  created() {
    // 初始化用户项目信息
    this.getProjectData();
  },
  mounted() {
    // 请求菜单栏
    this.menulistRequest();
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      var that = this;
      console.log(to.path, from.path);
    
      if(to.path=='/3DShow'){
        console.log('11')
        location.reload();
      }
      
    },
  },

  methods: {
    // 刷新页面
    refresh() {
      location.reload();
      // this.$router.go(0)
    },
    // 跳转页面
    routerUrl(url) {
      // console.log(url);
      this.$router.push(url);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 请求菜单栏
    menulistRequest() {
      this.$get("/user/role/menuTreeListByUid")
        .then((res) => {
          // console.log(res.data);
          this.menuList = res.data;
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取项目信息
    getProjectData() {
      let param = {
        pageNum: "1",
        pageSize: "100",
        uid: localStorage.getItem("uid"),
      };
      this.$post("/userProject/pageList", param)
        .then((res) => {
          this.projectList = res.data.list;
          this.selectedData = JSON.parse(localStorage.getItem("selectedData"));
          // 如果取出的数据存在，则将其赋值给el-select
          if (this.selectedData || this.selectedData != null) {
            this.projectName = this.selectedData;
            // 如果项目信息不存在，则取第一个为默认项目
          } else {
            this.projectName = this.projectList[0].projectId
            localStorage.setItem("projectID", this.projectName);
            // console.log(this.projectName)
          }
          // console.log(this.projectList)
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 选择项目
    changeProject(value) {
      this.selectedData = value;
      // 将选中的数据保存到本地存储中
      localStorage.setItem("selectedData", JSON.stringify(this.selectedData));
      this.projectId = value;
      // 保存projectID
      localStorage.setItem("projectID", value);
      // console.log(localStorage.getItem('projectID'))
    },
    // 跳转首页
    toIndex() {
      this.$router.push("/index");
    },
    // 打开退出登录
    logOpen() {
      this.$confirm("确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warnimg",
      })
        .then((status) => {
          if (status == "confirm") {
            this.logout();
          }
        })
        .catch((status) => {
          if (status == "cancel") {
            this.cancelLogout();
          }
        });
    },
    // 退出登录
    logout() {
      //   window.sessionStorage.clear();
      //   this.clearCookie();
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-icon-s-unfold {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: -5px;
}

.el-icon-s-fold {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: -10px;
}

.home {
  width: 100%;
  display: flex;
  background-color: rgb(29, 68, 128);

  /* 头部 */
  .header {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);

    .control {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 5px;

      .title {
        margin: -8px 0 0 10px;
        cursor: pointer;
      }

      .select-project {
        width: 150px;
        margin-left: 20px;
        margin-top: -5px;

        li.el-select-dropdown__item {
          color: #606266 !important;
        }
      }
    }

    .avatar {
      display: flex;
      align-items: center;

      span {
        vertical-align: middle;
        margin: 5px 10px 0 10px;
        cursor: pointer;
      }
    }

    // 退出登录
    .logout:hover {
      background-color: #eee;
      width: 100%;
      height: 100%;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
}

.menulist {
  height: 100vh;
  background-color: rgb(29, 68, 128);
  box-sizing: border-box;
  text-align: center;
  padding-top: 23px;
  box-sizing: border-box;
  // .el-submenu {
  //   // padding-left:  30px !important;
  //   text-align: left;
  //   .el-submenu__title{
  //   padding-left: 50px !important;
  // }
  // }

  // .el-menu--collapse  .el-submenu{
  //   padding: 0;
  // }
  // .el-menu-item{
  //   padding-left: 50px !important;
  //   text-align: left;
  //   box-sizing: border-box;
  // }

  // .el-menu--collapse .el-menu-item.is-active{
  //   padding-left: 10px !important;
  // }
  // .el-menu--collapse .el-menu-item.is-hover{
  //   padding-left: 10px !important;
  // }
  .bigLogo {
    width: 125px;
    // padding-left:  35px;
  }

  .smallLogo {
    width: 36px;
    // padding-left:  10px;
  }
}
</style>
<style>
/* element-ui菜单样式修改 */
.el-menu {
  background-color: rgb(29, 68, 128);
  border: none !important;
}

.el-menu .el-menu-item:hover {
  outline: 0 !important;
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}

.el-menu .el-menu-item.is-active {
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}

.el-submenu .el-submenu__title:hover {
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}
</style>
